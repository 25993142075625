import $ from 'jquery';
import Cookies from 'js-cookie';
import ScrollMagic from 'scrollmagic';
import './form.js';
import './app.js';

window.jQuery = window.$ = $;
var controller = new ScrollMagic.Controller();

$(function() {
    $.fn.extend({
        toggleText: function(a, b){
            return this.text(this.text() == b ? a : b);
        }
    });

    // Set cookies
    // Accept cookies
    $("#cookies-button").on("click", function() {
        Cookies.set('privacy-policy', true);
        $("#cookies-modal").hide();
    });

    if(Cookies.get('privacy-policy')) {
        $("#cookies-modal").hide();
    } else {
        $("#cookies-modal").show();
    }

    // Hide cookie notice close (Temp)
    $("#close").on("click", function(){
        $("#cookies-modal").hide();
    });
    

    // Answer HCP question
    $("#hcp-only .button").on("click", function() {
        Cookies.set('hcp', true);
        $(".overlay, .modal").hide();
        if(!Cookies.get('privacy-policy')) {
            $("#cookies-modal").show();
        }
    });

    if(Cookies.get('hcp')) {
        $(".overlay, #hcp-only").hide();
        $("body").removeClass("modal-active");
    } else {
        $(".overlay, #hcp-only").show();
        $("body").addClass("modal-active");
        $("#cookies-modal").hide();
    }

    $(".modal-leaving").on("click", function(){
        var newURL = $(this).data("href");
        $(".overlay, #leaving").show();
        $("body").addClass("modal-active");
        $("#leaving").find("a.button").attr("href", newURL)
    });

    $("#leaving .button").on("click", function() {
        // Remove the modal after the user opens a new tab
        $(".overlay, .modal").hide();
    });

    if($(this).scrollTop() > 200) {
        $("header").addClass("scrolled");
        $("nav.primary").addClass("scrolled");
        $(".mobile-nav").addClass("scrolled");
    }

    // Convert navbar to solid background
    $(window).on("scroll", function() {
        // Make the navbar solid background after scrolling down the page
        if(($(window).width() < 768 && $(this).scrollTop() > 200) || ($(window).width() > 769 && $(this).scrollTop() > 550)) {
            // Only shift the navbar on mobile
            $("header").addClass("scrolled");
            $("nav.primary").addClass("scrolled");
            $(".mobile-nav").addClass("scrolled");
            $(".disclaimer").css("background", "#000101");
            $(".main-nav").css("background", "linear-gradient(90deg, #003940 0%, #006062 49.94%, #003840 99.45%)");
        } else {
            $("header").removeClass("scrolled");
            $("nav.primary").removeClass("scrolled");
            $(".mobile-nav").removeClass("scrolled");
            $(".disclaimer").css("background", "");
            $(".main-nav").css("background", "");
        }
    });

    // Toggle button for individual item
    $(".item h3, .item p.description").on("click", function() {
        $(this).parent().find("h3").toggleClass('rotate');
        $(this).parent().find(".answer").toggleClass('opened');
    });

    $(".accordion-faq .faq-accordion").on("click", function() {
        $(this).toggleClass('rotate');
        $(this).parent().toggleClass('item-opened');
        $(this).find(".answer").toggleClass('opened');
    });

    $("#specialty .faq-accordion .item, #distribution-network .faq-accordion .item").on("click", function() {
        $(this).find(".faq-accordion").toggleClass('rotate');
        $(this).find(".answer").toggleClass('opened');
    });

    $(".mobile-nav").on("click", function() {
        $("nav.primary").toggleClass("opened");
        $(".mobile-nav").toggleClass("opened");
        if($(this).text() === "MENU") {
            $(".mobile-nav").text("✕");
            $(".overlay").show();
        } else {
            $(".mobile-nav").text("MENU");
            $(".overlay").hide();
        }
    });

    $("nav.primary .dropdown").on("click", function() {
        $(this).toggleClass("opened");
    });

    $(".overlay").on("click", function() {
        $(".modal, aside, .overlay").hide();
        $(".mobile-nav, nav.primary").removeClass("opened");
        $(".mobile-nav").text("MENU");
    });

    $("a.return, span.close").on("click", function() {
        $(".modal, aside, .overlay").hide();
    });
    

    // Generate overlay isi
    var isiSection = $("#isi").find(".isi-section").html();
    $(".overlay-isi").append(isiSection);

    // Hide overlay ISI when the full ISI comes into view
    new ScrollMagic.Scene({triggerElement: "#isi", offset: -180})
        .on('enter', () => {
            $(".overlay-isi").hide();
        })
        .on('leave', () => {
            $(".overlay-isi").show();
        })
        .addTo(controller);
    
});